import React, { useState, useEffect } from "react";
import "./Header.css";
import dev from "../../assets/dev.png";
import { useDarkMode } from "../../context/DarkModeContext";
import Lottie from "lottie-react";
import sun from "../../assets/Skills/sun.json";
import moon from "../../assets/Skills/moon.json";
import { IoSunnySharp } from "react-icons/io5";
import { IoIosMoon } from "react-icons/io";

const Header = () => {
  const { darkMode, toggleDarkMode } = useDarkMode();
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState("#home");

  const handleDarkModeToggle = () => {
    toggleDarkMode();
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        "#home",
        "#about",
        "#skills",
        "#services",
        "#qualification",
        "#projects",
        "#contact",
      ];
      const scrollPosition = window.scrollY;

      // Add a buffer for the scroll check to improve accuracy
      const buffer = 50;

      for (const section of sections) {
        const element = document.querySelector(section);
        if (element) {
          const top = element.offsetTop - buffer; // Adjust the top with a buffer
          const bottom = top + element.offsetHeight;
          if (scrollPosition >= top && scrollPosition < bottom) {
            setActiveNav(section);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <header className={`header ${darkMode ? "dark-theme" : ""}`}>
      <nav className="nav container">
        <a href="#home" className="nav__logo">
          Asad
          <img src={dev} width={30} alt="" />
        </a>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
            <ul className="nav__list">
              <li className="nav__item">
                <a
                  href="#home"
                  className={
                    activeNav === "#home"
                      ? "nav__link active-link"
                      : "nav__link"
                  }
                >
                  Home
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#about"
                  className={
                    activeNav === "#about"
                      ? "nav__link active-link"
                      : "nav__link"
                  }
                >
                  About
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#projects"
                  className={
                    activeNav === "#projects"
                      ? "nav__link active-link"
                      : "nav__link"
                  }
                >
                  Projects
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#skills"
                  className={
                    activeNav === "#skills"
                      ? "nav__link active-link"
                      : "nav__link"
                  }
                >
                  Skills
                </a>
              </li>
              {/* <li className="nav__item">
                <a
                  href="#qualification"
                  className={
                    activeNav === "#qualification"
                      ? "nav__link active-link"
                      : "nav__link"
                  }
                >
                  Portfolio
                </a>
              </li> */}
              {/* <li className="nav__item">
                <a
                  href="#services"
                  className={
                    activeNav === "#services"
                      ? "nav__link active-link"
                      : "nav__link"
                  }
                >
                  Services
                </a>
              </li> */}
              <li className="nav__item">
                <a
                  href="#contact"
                  className={
                    activeNav === "#contact"
                      ? "nav__link active-link"
                      : "nav__link"
                  }
                >
                  Contact
                </a>
              </li>
            </ul>
            <i
              className="uil uil-times nav__close"
              onClick={() => showMenu(!Toggle)}
            ></i>
          </div>
          <div className="sunMoon">
            <span className="toggle-link" onClick={handleDarkModeToggle}>
              {!darkMode ? (
                <IoSunnySharp
                  className="sun"
                  style={{ fontSize: "25px", color: " #ef810e" }}
                />
              ) : (
                <IoIosMoon
                  className="moon"
                  style={{ fontSize: "25px", color: "#fff" }}
                />
              )}
            </span>
          </div>
        </div>
        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
