import React, { useState } from "react";
import github from "../../assets/Skills/github.png";

const WorkItem = ({ item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false); // State for managing the copied text

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);

    if (!isModalOpen) {
      document.body.classList.add("modal-open");
      document.documentElement.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
      document.documentElement.classList.remove("modal-open");
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(item.url); // Copy the URL to clipboard
    setCopied(true); // Set the copied state to true

    // Reset the copied state after 3 seconds
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div className="work__card" key={item.id}>
      <img src={item.image} alt="" className="work__img" />

      <h3 className="work__title">
        {item.title}{" "}
        {item.category !== "Illustration" ? (
          <span style={{ color: "#F7AB04" }}>Application</span>
        ) : (
          ""
        )}
      </h3>
      {item.category !== "Illustration" && item.category !== "Figma" ? (
        <span
          className="work__button"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={toggleModal}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ color: "#F7AB04" }}>Click here</span>
            <i className="bx bx-right-arrow-alt work__button-icon"></i>
          </div>
        </span>
      ) : (
        ""
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="work__modal active-modal">
          <div className="work__modal-content">
            <i
              className="uil uil-times work__modal-close"
              onClick={toggleModal}
            ></i>
            <img src={item.image} alt="" className="work__modal-img" />
            {/* Copy Button */}
            <div className="work__modal-copy">
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span style={{ fontWeight: 500}}>{item.url}</span>
                <button
                  onClick={handleCopy}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "#F7AB04",
                    display: "flex",
                    alignItems: "center",
                    fontSize: copied ? "12px" : "18px"
                  }}
                >
                  {copied ? "Copied ✓" : <i className="bx bx-copy"></i>}
                </button>
              </div>
            </div>
            <div className="work__divider"></div>
            <h3 className="work__modal-title">{item.title}</h3>
            <p className="work__modal-description">{item.description}</p>
            <p className="work__modal-techStack">
              <strong>Tech Stack:</strong>
              <div className="work__techStack-icons">
                {item.techStack.map((tech, index) => (
                  <img
                    key={index}
                    src={tech}
                    alt="Tech logo"
                    className="work__techStack-icon"
                  />
                ))}
              </div>
            </p>

            <div className="work__modal-links">
              <a
                href={item.url}
                target="_blank"
                rel="noreferrer"
                className="work__modal-link"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ color: "#F7AB04" }}>Demo</span>
                  <i className="bx bx-right-arrow-alt work__button-icon"></i>
                </div>
              </a>

              {item.repo && (
                <a
                  href={item.repo}
                  target="_blank"
                  rel="noreferrer"
                  className="work__modal-link"
                >
                  <img src={github} width={35} alt="GitHub Repo" />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkItem;
